// ==========================================================
// 	Layout - main
// ==========================================================


.l-page{
}

@include _mq-down(md) {
    .l-page{
    }
}

/*Hide Style ---------------------------------------*/
.hide-pc{display: none;}
.hide-sp{display: inline-block;}
@include _mq-down(md) {
    .hide-pc{display: inline-block;}
    .hide-sp{display: none;}
}

/*Entire ---------------------------------------*/
.container{
    width: 100%;
    display: -webkit-flex;
    display: flex;
}

/*Contents Area ---------------------------------------*/
main{
    width: 75%;
    margin-left: 25%;
    display: block;
}
section{
    width: 100%;
    max-width: 1500px;
}
h3{
    color: #141414;
    margin: 50px auto 25px;
    padding: 45px 0 7px;
    background: url('../../assets/img/common/common_hicon.svg');
    background-repeat: no-repeat;
    background-position: center top;
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    position: relative;
}
h3:after{
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0px;
    display: inline-block;
    width: 30px;
    height: 3px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #6EAA1E;
}
dl{
    width: 100%;
    margin: 0px auto 5px;
    display: flex;
}
dt,dd{
    float: left;
    padding: 20px 20px 20px;
    box-sizing: border-box;
    line-height: 1.75;
    vertical-align: middle;
}
dt{
    width: 30%;
    color: #fff;
    background-color: #6EAA1E;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
dd{
    width: 70%;
    background-color: #fff;
    box-sizing: border-box;
}
@include _mq-down(md) {
    main{
        width: 100%;
        margin-top: 100px;
        margin-left: 0%;
    }
    section{
        max-width: 100%;
    }
    dl{
        display: inline-block;
    }
    dt,dd{
        float: none;
        width: 100%;
        text-align: center;
    }
}
