 /* #Link-ext
   -------------------------------------------------------------------------- */
/*doc
---
name: Link-ext
category: Component
tag: Link
---

外部リンクであることを示す場合に使います。

```ejs
<p><a class="c-link" href="#">テキストリンクです。</a></p>
<p><a class="c-link-ext" href="#">外部リンクを開く<span class="c-link-ext__icon" aria-hidden="true"></span></a></p>
```
*/

$icon-linkExternal: "\EA02";

.c-link-ext {
  color: $color-link;
  text-decoration: none;

  &:hover {
    color: $color-link-hover;
    text-decoration: underline;
  }
}

.c-link-ext__icon:after {
  @include icon;
  content: "#{$icon-LinkExternal}";
  top: -0.15em;
  left: 0.25em;
  font-size: 1.25em;
}
