 /* #Link
   -------------------------------------------------------------------------- */
 /*doc
---
name: Mask
category: Component
tag: Image
---

画像用ハッチング

```ejs
<figure class="c-mask"><img src="https://placehold.jp/150x150.png"></figure>
```
*/

.c-mask {
    //ハッチング画像定義
    $hatching: $bg-st-white30;
    $hatching-size: 5px;
    display: inline-block;
    position: relative;
    &::before {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url($hatching);
        background-size: $hatching-size;
    }
}
