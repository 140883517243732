 /* #Icon
   -------------------------------------------------------------------------- */
/*doc
---
name: Iconfont
category: Component
tag: Icon
---

アイコンフォントを使ったアイコンを表示します。デフォルトでは`:before`擬似要素で表示されます。

```ejs
<p>More <span class="c-iconfont c-iconfont--LinkDownload" aria-hidden="true"></span></p>
<p>More <span class="c-iconfont c-iconfont--LinkExternal" aria-hidden="true"></span></p>
<p>More <span class="c-iconfont c-iconfont--LinkPdf" aria-hidden="true"></span></p>
<p>More <span class="c-iconfont c-iconfont--LinkPdf c-icon--rotate90" aria-hidden="true"></span></p>
```
*/
@font-face {
  font-family: "iconfont";
  src: url('../font/iconfont.eot');
  src: url('../font/iconfont.eot?#iefix') format('eot'),
    url('../font/iconfont.woff') format('woff'),
    url('../font/iconfont.ttf') format('truetype'),
    url('../font/iconfont.svg#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

// アイコンフォントのベーススタイルです。
@mixin icon {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  top: -.1em;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: none;
  text-transform: none;
  speak: none;
}

// アイコンの回転
.c-iconfont{
  &--rotate90{
    transform: rotate(90deg);
  }
  &--rotate180{
    transform: rotate(180deg);
  }
  &--rotate270{
    transform: rotate(-90deg);
  }
}

// アイコンフォントを`content: "";`で表示できるように変数化しています。
// インターポレーション（`#{}`）の中に入れる必要があります。
// example: content: "#{$icon-LinkMore}";
$icon-LinkDownload: "\EA01";
$icon-LinkExternal: "\EA02";
$icon-LinkMore: "\EA03";
$icon-LinkPdf: "\EA04";


/* 汎用的なアイコンフォントです。 */
.c-iconfont { display: inline-block; }
.c-iconfont:before { @include icon; }

.c-iconfont--LinkDownload:before { content: "\EA01"; }
.c-iconfont--LinkExternal:before { content: "\EA02"; }
.c-iconfont--LinkMore:before { content: "\EA03"; }
.c-iconfont--LinkPdf:before { content: "\EA04"; }
