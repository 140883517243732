 /* #Button
   -------------------------------------------------------------------------- */
 /*doc
---
name: Button
category: Component
tag: [Link, Form]
---

ボタンのデフォルトスタイルです。

```ejs
<p>
  <a class="c-button" href="#">aタグボタン</a>
  <a class="c-button c-button--disabled" href="#">aタグボタン</a>
  <button class="c-button" type="button">buttonタグボタン</button>
  <button class="c-button" disabled type="button">buttonタグボタン</button>
</p>
```
*/

.c-button {
    display: inline-block;
    margin: 0;
    padding: 1em 2em;
    border: none;
    border: 1px solid #d0d0d0;
    color: inherit;
    font-family: inherit;
    font-size: 1em;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    background: transparent;
    background-color: #fff;
    cursor: pointer;
    appearance: none;
    @include _on-event() {
        text-decoration: none;
    }
    &:focus {}
    &:disabled,
    &--disabled {
        cursor: $form-disabled-cursor;
        opacity: $form-disabled-opacity;
        background-color: $form-disabled-background-color;
    }
    &--disabled {
        pointer-events: none;
    }
}

