// ----------------------------------------
//  Object - Utylity - text
// ----------------------------------------

// font-size - utility //
.u-fz-xs {
    font-size: 69% !important;
}
.u-fz-sm {
    font-size: 88% !important;
}
.u-fz-md {
    font-size: 100% !important;
}
.u-fz-lg {
    font-size: 125% !important;
}
.u-fz-xl {
    font-size: 150% !important;
}

// text-decoration - utility //
.u-fw-n {
    font-weight: normal !important;
}
.u-fw-b {
    font-weight: bold !important;
}
.u-td-u {
    text-decoration: underline !important;
}
.u-td-n {
    text-decoration: none !important;
}

// Alignment
// Alignment

.u-text-justify {
    text-align: justify !important;
}
.u-text-nowrap {
    white-space: nowrap !important;
}
.u-text-truncate {
    @include text-truncate;
}

.u-text-brand {
    color: $color-brand !important;
}

.u-font-ls-sm {
    letter-spacing: $font-ls-sm;
}
.u-font-ls-md {
    letter-spacing: $font-ls-md;
}
.u-font-ls-lg {
    letter-spacing: $font-ls-lg;
}

