//Noto Sans JP
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
// @import url(//fonts.googleapis.com/css?family=Montserrat);

// よく使うGoogleFonts
// Noto Sans JP
// @import url(//fonts.googleapis.com/earlyaccess/notosansjp.css);
//
// Montserrat (Gothemの代替)
// @import url(//fonts.googleapis.com/css?family=Montserrat);
//
// Lato (Gill Sansの代替)
// @import url(//fonts.googleapis.com/css?family=Lato);
//
// Poppings
// @import url(https://fonts.googleapis.com/css?family=Poppins:600);
//
// 組み合わせて指定する場合
// @import url(//fonts.googleapis.com/css?family=Lato|Montserrat);
