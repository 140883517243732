// @desc - 要素を親要素内でセンタリングします。親要素にposition: relatibeかabsoluteを指定してください。
// @example scss - Usage
//
// .foo { @include centering; }
// .bar { @include centering(empty); }
//
// @example css - CSS output
// .foo {
//  position: absolute;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  left: 0;
// }
// .bar {
//  position: absolute;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  background-color: #fff;
//  opacity: 0;
//  filter: alpha( opacity=0 );
// }
@mixin _centering() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin _centering-vertical() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin _centering-horizon() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
