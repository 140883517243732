// ----------------------------------------
//  Object - Utylity - background
// ----------------------------------------

// background - utility //

.u-bg-brand {
    background-color: $color-brand;
    color: #fff;
}
