/* #Link
   -------------------------------------------------------------------------- */
/*doc
---
name: Link
category: Component
tag: Link
---

テキストリンクのデフォルトスタイルです。

```ejs
<p><a class="c-url" href="#">テキストリンクです。</a></p>
```
*/
.c-link {
    color: $color-link;
    text-decoration: none;

    @include _on-event {
        color: $color-link-hover;
        text-decoration: underline;
    }
}
