 /* #Header
   -------------------------------------------------------------------------- */
.l-header {}
.l-header__logo-area {}
.l-header__logo {}
.l-header__nav-area {}
.l-header__nav {}
.l-header__nav-item {}
.l-header__nav-link {}
.l-header__background{}


/*ヘッダー*/
/*Navigation ---------------------------------------*/
header {
    z-index: 5555;
    @include _mq-down(md) {/*width: 25%;*/
    position: fixed;
    top: 0px;
    left: 0px;
    /*background-color: #fff;*/
    }
}
.nav-logo{
    width: 150px;
    height: 190px;
    margin: 50px auto 65px;
}
.navPC_wrap {
    position: relative;
}
.floating {

}
.navPC{
    position: fixed;
    /*top: 0;
    left: 0;*/
    width: 25%;
    margin: 0px;
    display: block;
    z-index: 1;
    background-color: #fff;
}
.navPC ul{
    font-size: 1.6rem;
    width: 80%;
    margin: 0px auto;
    text-align: center;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}
.navPC ul li,
.navSP ul li{
    border-bottom: 1px solid #dcdcdc;
    padding: 18px 0px;
    box-sizing: border-box;
}
.navPC ul li:last-child,
.navSP ul li:last-child{
    border-bottom: none;
}
header a{
    text-decoration: none;
    color: #464646;
}

/*active*/
.nav-active {
    color: #464646;
}
#home .nav-home, #business .nav-business, #recruit .nav-recruit, #partner .nav-partner {
    color: #6EAA1E;
}
header a:hover{
    color: #6EAA1E;
}
.nav-logo_sp,
.navSP{
    display: none;
}
@include _mq-down(md) {
    header{
        width: 100%;
        height: 100px;
    }
    header a:hover{
        color: #464646;
    }
    .navPC{display: none;}

    .nav-logo_sp{
        display: block;
        padding: 16px 10px;
        box-sizing: border-box;
        background-color: #fff;
    }
    .nav-logo_sp figure a img {
        width: 230px;
        height: 65px;
    }
    .navSP{
        width: 90%;
        background: #fff;
        position: fixed;
        top: 0px;
        right: 0px;
    }
    .navSP ul li{
        padding-left: 15px;
        box-sizing: border-box;
    }
}

/*スマホメニュー*/
.l-gnav-toggle-area {
    display: none;
    /*float: right;*/
    width: 60px;
    height: 60px;
    background-color: rgba(255,255,255,.7);
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    /*position: relative;*/
    padding: 0;
    z-index: 9999;
    @include _mq-down(md) {
        display: block;
        position: fixed;
        right: 0;
        top: 0;
    }
}

.l-gnav-toggle {
    width: 25px;
    height: 60px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    top: 15px;
    > span {
        position: absolute;
        left: 0;
        display: inline-block;
        width: 100%;
        height: 2px;
        background-color: $color-brand;
        transition: 0.2s $ease-out-cubic;
        transform-origin: center;
    }
    > span:nth-child(1) {
        top: 0px;
    }
    > span:nth-child(2) {
        top: 8px;
    }
    > span:nth-child(3) {
        top: 16px;
    }
}

.l-gnav-sp-area {
    position: fixed;
    z-index: 9999;
    top: 60px;
    right: 0;
    width: 80%;
    height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    transform: translate3d(105%, 0, 0);
    transition: 0.4s $ease-out-cubic;
}

.l-gnav-sp {
    background-image: url('../../assets/img/common/dot_gray40.png');
    padding: 10px 0 10px 10px;
    &__item {
        border-top: solid 1px #dcdcdc;
        > a {
            font-size: 1.5rem;
            display: block;
            height: 70px;
            line-height: 70px;
            background: #fff;
            color: #464646;
            padding-right: 20px;
            padding-left: 40px;
            position: relative;
        }
        &.home_btn {
            border-top: 0;
        }
        &.home_btn a {
            color: $color-brand;
            font-size: 1.6rem;
            background-image: url('../../assets/img/common/dot_gray10.png');
        }
    }

    li a::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 20px;
        width: 6px;
        height: 6px;
        margin-top: -2px;
        border: 0;
        border-top: solid 2px #464646;
        border-right: solid 2px #464646;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    &__languge a{
        padding-left:18px;
        color :#fbb03b;
    }
    &__languge a img{
        margin-right:7px;
    }
    &__languge a::before{
        display: none;
    }
}
li.l-gnav-sp__item.home_btn a::before {
    border-top: solid 2px $color-brand;
    border-right: solid 2px $color-brand;
}

li.l-gnav-sp__item{
    a{
        &.active {
            background: rgba(20, 30, 100, 1);
        }

        &:hover{
            /*background: rgba(128, 128, 128, 1);*/
            background-image: url('../../assets/img/common/dot_gray10.png');
        }
    }
}

.l-gnav-toggle-text {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    font-size: 0.735rem;
    line-height: 1;
    letter-spacing: 0.5px;
    left: 0.5px;
    font-weight: bold;
    color: #646464;
    &__menu,
    &__close {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 15px;
        transition: 0.4s $ease-in-out;
    }
    &__close {
        opacity: 0;
    }
}

.is-drawer-open {
    .l-gnav-toggle {
        > span {
            background: #4d4d4d;
            transition: .4s;
        }
        > span:nth-child(1) {
            transform: translate3d(0, 8px, 0) rotate(45deg);
        }
        > span:nth-child(2) {
            transform: scale(0);
            opacity: 0;
        }
        > span:nth-child(3) {
            transform: translate3d(0, -8px, 0) rotate(-45deg);
        }
    }
    .l-gnav-toggle-text {
        &__menu {
            opacity: 0;
        }
        &__close {
            opacity: 1;
        }
    }
    .l-gnav-background {
        visibility: visible;
    }
}

.is-drawer-open .l-gnav-sp-area {
    transform: translate3d(0, 0, 0);
}
