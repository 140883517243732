 /* #LinkDownload
   -------------------------------------------------------------------------- */
 /*doc
---
name: LinkDownload
category: Component
tag: Link
---

ファイルをダウンロードするためのリンクであることを示す場合に使います。

```ejs
<p><a class="c-link" href="#">テキストリンクです。</a></p>
<p><a class="c-link-dl" href="#">ファイルをダウンロードする<span class="c-link-dl__icon" aria-hidden="true"></span></a></p>
```
*/

.c-link-dl {
    color: $color-link;
    text-decoration: none;
    &:hover {
        color: $color-link-hover;
        text-decoration: underline;
    }
}
.c-link-dl__icon::after {
    @include icon;
    content: "#{$icon-LinkDownload}";
    top: -0.2em;
    left: 0.25em;
    font-size: 1.25em;
}

