 /* #Divider
   -------------------------------------------------------------------------- */
 /*doc
---
name: Avator
category: Component
tag: Image
---

アバター画像用に画像を円でマスクします。

```ejs
<p><img src="https://placehold.jp/150x150.png" class="c-avator"></p>
```
*/

.c-avator {
    border-radius: 50%;
}

