// Object - component - banner

/*doc
---
name: Banner
category: Component
tag: Link
---

バナー画像のアニメーション

```ejs
<p class="c-banner"><img src="https://placehold.jp/150x150.png"></p>
```
*/

.c-banner {
    transition: opacity 0.7s $ease-out-cubic;
    @include _on-event {
        opacity: 0.7;
    }
}
