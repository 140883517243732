/* --------------------------------------------------------------------------
   #base
  -------------------------------------------------------------------------- */

html {
    box-sizing: border-box;
    font-size: 62.5%;
    overflow-y: scroll;
    @if $tablet-control {
        // タブレット表示のとき、PC表示を縮小させる場合は有効化 _global.scssで制御
        overflow-x: auto;
        min-width: 1024px;
        @include _mq-down(md) {
            min-width: 0;
        }
    }
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
body,
input,
textarea,
keygen,
select {
    @include _font-rem(map-get($font-size, default));
    font-family: $font-family-sans-serif;
    line-height: $font-line-height;
    font-weight: $font-weight;
    letter-spacing: $font-ls-default;
}

body {
    color: $color-text;
    background-color: $color-base;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: unset;
    -webkit-text-size-adjust: 100%;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 2dppx) {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

[lang="en"] {
    font-family: $font-family-eng;
}

/**
* `dt`と`dd`の左端を揃えます。
*/

dd {
    margin-left: 0;
}

/**
 * 日本語では斜体を使用しないためリセットします。
 */
i,
cite,
em,
address,
dfn {
    [lang="ja"] & {
        font-style: normal;
    }
}

/**
* フルードイメージにします。
*/

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

code,
kbd,
samp {
    font-family: $font-family-monospace;
    font-size: 1em;
}

/* IE10以降`input[type="text"]`の入力内容の消去を非表示にします。 */

input::-ms-clear {
    visibility: hidden;
}

/* IE10以降の`input[type="password"]`のマスクの一時的解除を非表示にします。 */

input::-ms-reveal {
    visibility: hidden;
}

/* Normalize.cssをリセットします。 */

figure {
    margin: 0;
}

/* ブロック要素の上下のマージンをリセットします。 */

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
blockquote,
p,
address,
hr,
table,
fieldset,
pre {
    margin-top: 0;
    margin-bottom: 0;
}

/* tableをリセットします。 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}
th {
    text-align: left;
}

/* リンクスタイル */

a {
    text-decoration: none;
    color: $color-link;
    @include _on-event {
        text-decoration: none;
        outline: none;
    }
}

/**
 * iOSのSafariとChromeで、`text-decoration: underline;`の罫線が
 * グリフやディセンダで途切れないようにします。
 * https://css-tricks.com/almanac/properties/t/text-decoration-skip/
 */

a {
    -webkit-text-decoration-skip: none;
}

/* リスト */

ul,
ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

/**
 * IEのimgとobject要素でもSVGをフルードイメージにします。
 * See: https://hail2u.net/blog/webdesign/max-width-and-svg-on-ie11-and-below.html
 */
[src$=".svg"],
[data$=".svg"] {
    width: 100%;
}

/**
 * キーボードでアクセスできない要素のフォーカス表示を消します。
 * Credit: https://github.com/suitcss/base
 */
[tabindex="-1"]:focus {
    outline: none !important;
}

/**
 * IE10以降の`input[type="text"]`で入力内容の消去機能を非表示にします。
 */
[type="text"]::-ms-clear {
    visibility: hidden;
}

/**
 * IE10以降の`input[type="password"]`でマスクの一時的解除機能を非表示にします。
 */
[type="password"]::-ms-reveal {
    visibility: hidden;
}

/**
 * IEとEdgeはスピンボタンに未対応のため、ChromeとFireFoxで非表示にします。
 */
[type="number"]::-webkit-outer-spin-button,
[type="number"]::-webkit-inner-spin-button {
    appearance: none;
}

[role="button"] {
    cursor: pointer;
}

/* 選択済みテキスト */

ins,
mark {
    text-decoration: none;
}
mark {
    background: $color-selected-text-base;
}
ins {
    background: $color-selected-text-base;
}
del {
    text-decoration: line-through;
}
del:after {
    content: " ";
}
::selection {
    background: $color-selected-text-base;
    color: $color-selected-text;
    text-shadow: none;
}
::-moz-selection {
    background: $color-selected-text-base;
    color: $color-selected-text;
    text-shadow: none;
}

/* 見出し */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
}
