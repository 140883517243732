 /*doc
---
name: Secure height
category: Utility
tag: [Helper]
---

ある要素に内容がないときでもテキスト１行分の高さを確保する。

```ejs
<p>↓↓↓↓↓↓↓↓↓↓↓</p>
<p class="u-secure-height"></p>
<p>↑↑↑↑↑↑↑↑↑↑↑</p>
```
*/

 .u-secure-height::before {
     content: "";
     display: inline-block;
 }
