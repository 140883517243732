/* #Label
   -------------------------------------------------------------------------- */
/*doc
---
name: Label
category: Component
tag: [Link, Form]
---

インラインで表示するラベルコンポーネントです。

```ejs
<p>
  <span class="c-label">spanタグのラベルです。</span>
  <label class="c-label">labelタグのラベルです。</label>
  <a class="c-label" href="#">aタグのラベルです。</a>
</p>
```
*/

.c-label {
    display: inline-block;
    padding: 0.5em 1em;
    @include _font-rem(10);
    line-height: 1;
    color: $color-text;
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    background-color: inherit;
    &:hover {
        text-decoration: none;
    }
}
