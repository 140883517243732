 /* #Label
   -------------------------------------------------------------------------- */
 /*doc
---
name: Scroll
category: Component
tag: Parts
---

スクロールバーを表示します。

```ejs
```
*/

 // スクロールバーの色
 $color-bar: $color-brand; // スクロールバーの前の色
 $color-start: #999; // スクロールバーの後の色
 $color-end: #eee; // スクロールバーの太さ
 $bar-width: 10px; // スクロールバーの丸み
 $bar-radius: 5px;
 @include _mq-down(md) {
     .c-scroll-horizon {
         overflow-x: scroll;
         -webkit-overflow-scrolling: touch;
         &::-webkit-scrollbar {
             width: $bar-width;
             background: #f5f5f5;
         }
         &::-webkit-scrollbar:horizontal {
             height: $bar-width;
             background: #f5f5f5;
         }
         &::-webkit-scrollbar-thumb:horizontal {
             background: $color-bar;
             border-radius: $bar-radius;
         }
         &::-webkit-scrollbar-track-piece:start {
             background: $color-start;
         }
         &::-webkit-scrollbar-track-piece:end {
             background: $color-end;
         }
         &::-webkit-scrollbar-corner {
             background-color: transparent;
         }
         &::-webkit-scrollbar-thumb {
             background: $color-brand;
             border-radius: $bar-radius;
         }
     }
 }
 @include _mq-down(md) {
     .c-scroll-vertical {
         overflow-y: scroll;
         -webkit-overflow-scrolling: touch;
         &::-webkit-scrollbar {
             width: $bar-width;
             background: #f5f5f5;
         }
         &::-webkit-scrollbar:vertical {
             height: $bar-width;
             background: #f5f5f5;
         }
         &::-webkit-scrollbar-thumb:vertical {
             background: $color-bar;
             border-radius: $bar-radius;
         }
         &::-webkit-scrollbar-track-piece:start {
             background: $color-start;
         }
         &::-webk it-scrollbar-track-piece:end {
             background: $color-end;
         }
         &::-webkit-scrollbar-corner {
             background-color: transparent;
         }
         &::-webkit-scrollbar-thumb {
             background: $color-brand;
             border-radius: $bar-radius;
         }
     }
 }
 .c-scroll-help {
     margin-top: .5em;
     text-align: right;
     color: #999;
     @include _font-rem(14);
 }
