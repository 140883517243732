 /* #FormRadio
   -------------------------------------------------------------------------- */
 /*doc
---
name: FormRadio
category: Component
tag: Form
---

`type="radio"`のデフォルトスタイルです。

```ejs
<input class="c-form-radio" type="radio" id="radio1" name="radio1">
<label for="radio1">ラジオボタン</label>

<input class="c-form-radio" type="radio" id="radio2" name="radio1">
<label for="radio2">ラジオボタン</label>

<input disabled class="c-form-radio" type="checkbox" id="radio3" name="radio2">
<label disabled for="radio3">ラジオボタン</label>
```
*/

.c-form-radio {
    display: none;
    &+label,
    &+span {
        position: relative;
        display: inline-block;
        cursor: pointer;
        height: 2.8em;
        line-height: 2.8;
        padding-left: 1.2em;
    }
    &+label:before,
    &+span:before {
        content: "";
        position: absolute;
        display: inline-block;
        vertical-align: middle;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 1em;
        height: 1em;
        margin: auto;
        margin-right: 0.25em;
        border: 1px solid #ccc;
        border-radius: 50%;
        background-color: #fff;
    }
    &:checked+label:after,
    &:checked+span:after {
        content: "";
        background-image: url("../img/common/icon/FormRadio.svg");
        background-repeat: no-repeat;
        background-size: 0.5em;
        position: absolute;
        top: -0.1em;
        bottom: 0;
        left: 0.25em;
        margin: auto;
        width: 1em;
        height: 1em;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
    }
    &:disabled,
    &+label[disabled],
    &+span[disabled] {
        cursor: $form-disabled-cursor;
        opacity: $form-disabled-opacity;
    }
    &:disabled+label:after,
    &:disabled+span:after {
        display: none;
    }
}

