 /* #TextSecondary
   -------------------------------------------------------------------------- */
/*doc
---
name: TextSecondary
category: Component
tag: Text
---

`<p>`や`<small>`で使われるようなテキストスタイルです。副次的な意味合いを持たせる場合に使います。

```ejs
<p>通常の文章です</p>
<p class="c-text-secondary">補足的な文章です</p>
```
*/
.c-text-secondary {
  font-size: $font-size-secondary;
}
