 /* #Text
   -------------------------------------------------------------------------- */
/*doc
---
name: Mincho
category: Component
tag: Text
---

サイト全体で使うテキストスタイルを定義します。

```ejs
<p class="c-text-mincho">ダミーテキストです。</p>
```
*/

.c-text-mincho {
  font-family: $font-family-serif;
}
