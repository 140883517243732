// Object - component - grid-system

/*
---
name: Grid
category: Component
tag: Grid
---

flexboxを使った12グリッドのグリッドシステムです。カラムごとに任意の値を指定したい場合に使います。

カラム数、ガターの大きさはfoundation/variable/_global.scss で指定します。
[$default-grid-columns $default-grid-gutter]

以下のような指定ができます。

- `.c-grid__col-8-12` カラム数の指定。
- `.c-grid--wide` ガターサイズ50px。
- `.c-grid--top` 要素の垂直位置を上寄せ。
- `.c-grid--middle` 要素の垂直位置を真ん中寄せ。
- `.c-grid--bottom` 要素の垂直位置を下寄せ。
- `.c-grid--center` 要素を中央寄せ、要素内は左寄せ。
- `.c-grid--right` 要素を右寄せ、要素内は左寄せ。
- `.c-grid--reverse` 要素の反転。

```ejs
<p>ガターサイズ：normal</p>
<div class="c-grid">
  <div class="c-grid__col c-grid__col--8of12 c-grid__col--12of12md">
    <img src="https://placehold.jp/1200x400.png" alt="">
  </div>
  <div class="c-grid__col c-grid__col--4of12 c-grid__col--6of12md">
    <img src="https://placehold.jp/600x400.png" alt="">
  </div>
  <div class="c-grid__col c-grid__col--4of12 c-grid__col--6of12md">
    <img src="https://placehold.jp/400x400.png" alt="">
  </div>
  <div class="c-grid__col c-grid__col--4of12 c-grid__col--6of12md">
    <img src="https://placehold.jp/400x400.png" alt="">
  </div>
  <div class="c-grid__col c-grid__col--4of12 c-grid__col--6of12md">
    <img src="https://placehold.jp/400x400.png" alt="">
  </div>
</div>
<p>ガターサイズ：wide</p>
<div class="c-grid c-grid--wide">
  <div class="c-grid__col c-grid__col--8of12 c-grid__col--12of12md">
    <img src="https://placehold.jp/1200x400.png" alt="">
  </div>
  <div class="c-grid__col c-grid__col--4of12 c-grid__col--6of12md">
    <img src="https://placehold.jp/600x400.png" alt="">
  </div>
  <div class="c-grid__col c-grid__col--4of12 c-grid__col--6of12md">
    <img src="https://placehold.jp/400x400.png" alt="">
  </div>
  <div class="c-grid__col c-grid__col--4of12 c-grid__col--6of12md">
    <img src="https://placehold.jp/400x400.png" alt="">
  </div>
  <div class="c-grid__col c-grid__col--4of12 c-grid__col--6of12md">
    <img src="https://placehold.jp/400x400.png" alt="">
  </div>
</div>
<p>要素を高さ中央揃え</p>
<div class="c-grid c-grid--middle">
  <div class="c-grid__col c-grid__col--8of12">
    <img src="https://placehold.jp/1200x800.png" alt="">
  </div>
  <div class="c-grid__col c-grid__col--4of12">
    <img src="https://placehold.jp/600x400.png" alt="">
  </div>
</div>

```
*/

$grid-gutter: $default-grid-gutter;
$grid-gutter-md: $default-grid-gutter-md;
$margin-bottom: $default-grid-margin-bottom;
$margin-bottom-md: $default-grid-margin-bottom-md;

$grid-gutter-wide: $default-grid-gutter;
$grid-gutter-wide-md: $default-grid-gutter-wide-md;
$margin-bottom-wide: $default-grid-margin-bottom;
$margin-bottom-wide-md: $default-grid-margin-bottom-wide-md;

.c-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 0 1 auto;
    align-items: stretch;
    margin-left: -$grid-gutter;
    margin-bottom: -$margin-bottom;
    padding-left: 0;
    list-style-type: none;
    @include _mq-down(md) {
        margin-bottom: -$margin-bottom-md;
        margin-left: -$grid-gutter-md;
    }
}
.c-grid__col {
    flex: 0 0 auto;
    flex-basis: 100%;
    max-width: 100%;
    padding-left: $grid-gutter;
    padding-bottom: $margin-bottom;
    @include _mq-down(md) {
        padding-left: $grid-gutter-md;
        padding-bottom: $margin-bottom-md;
    }
}
.c-grid__col {
    &--1of12 {
        max-width: percentage(1 / 12);
        flex-basis: percentage(1 / 12);
    }
    &--2of12 {
        max-width: percentage(2 / 12);
        flex-basis: percentage(2 / 12);
    }
    &--3of12 {
        max-width: percentage(3 / 12);
        flex-basis: percentage(3 / 12);
    }
    &--4of12 {
        max-width: percentage(4 / 12);
        flex-basis: percentage(4 / 12);
    }
    &--5of12 {
        max-width: percentage(5 / 12);
        flex-basis: percentage(5 / 12);
    }
    &--6of12 {
        max-width: percentage(6 / 12);
        flex-basis: percentage(6 / 12);
    }
    &--7of12 {
        max-width: percentage(7 / 12);
        flex-basis: percentage(7 / 12);
    }
    &--8of12 {
        max-width: percentage(8 / 12);
        flex-basis: percentage(8 / 12);
    }
    &--9of12 {
        max-width: percentage(9 / 12);
        flex-basis: percentage(9 / 12);
    }
    &--10of12 {
        max-width: percentage(10 / 12);
        flex-basis: percentage(10 / 12);
    }
    &--11of12 {
        max-width: percentage(11 / 12);
        flex-basis: percentage(11 / 12);
    }
    &--12of12 {
        max-width: percentage(12 / 12);
        flex-basis: percentage(12 / 12);
    }
}
@include _mq-down(lg) {
    .c-grid__col {
        &--1of12lg {
            max-width: percentage(1 / 12);
            flex-basis: percentage(1 / 12);
        }
        &--2of12lg {
            max-width: percentage(2 / 12);
            flex-basis: percentage(2 / 12);
        }
        &--3of12lg {
            max-width: percentage(3 / 12);
            flex-basis: percentage(3 / 12);
        }
        &--4of12lg {
            max-width: percentage(4 / 12);
            flex-basis: percentage(4 / 12);
        }
        &--5of12lg {
            max-width: percentage(5 / 12);
            flex-basis: percentage(5 / 12);
        }
        &--6of12lg {
            max-width: percentage(6 / 12);
            flex-basis: percentage(6 / 12);
        }
        &--7of12lg {
            max-width: percentage(7 / 12);
            flex-basis: percentage(7 / 12);
        }
        &--8of12lg {
            max-width: percentage(8 / 12);
            flex-basis: percentage(8 / 12);
        }
        &--9of12lg {
            max-width: percentage(9 / 12);
            flex-basis: percentage(9 / 12);
        }
        &--10of12lg {
            max-width: percentage(10 / 12);
            flex-basis: percentage(10 / 12);
        }
        &--11of12lg {
            max-width: percentage(11 / 12);
            flex-basis: percentage(11 / 12);
        }
        &--12of12lg {
            max-width: percentage(12 / 12);
            flex-basis: percentage(12 / 12);
        }
    }
}
@include _mq-down(md) {
    .c-grid__col {
        &--1of12md {
            max-width: percentage(1 / 12);
            flex-basis: percentage(1 / 12);
        }
        &--2of12md {
            max-width: percentage(2 / 12);
            flex-basis: percentage(2 / 12);
        }
        &--3of12md {
            max-width: percentage(3 / 12);
            flex-basis: percentage(3 / 12);
        }
        &--4of12md {
            max-width: percentage(4 / 12);
            flex-basis: percentage(4 / 12);
        }
        &--5of12md {
            max-width: percentage(5 / 12);
            flex-basis: percentage(5 / 12);
        }
        &--6of12md {
            max-width: percentage(6 / 12);
            flex-basis: percentage(6 / 12);
        }
        &--7of12md {
            max-width: percentage(7 / 12);
            flex-basis: percentage(7 / 12);
        }
        &--8of12md {
            max-width: percentage(8 / 12);
            flex-basis: percentage(8 / 12);
        }
        &--9of12md {
            max-width: percentage(9 / 12);
            flex-basis: percentage(9 / 12);
        }
        &--10of12md {
            max-width: percentage(10 / 12);
            flex-basis: percentage(10 / 12);
        }
        &--11of12md {
            max-width: percentage(11 / 12);
            flex-basis: percentage(11 / 12);
        }
        &--12of12md {
            max-width: percentage(12 / 12);
            flex-basis: percentage(12 / 12);
        }
    }
}
@include _mq-down(sm) {
    .c-grid__col {
        &--1of12sm {
            max-width: percentage(1 / 12);
            flex-basis: percentage(1 / 12);
        }
        &--2of12sm {
            max-width: percentage(2 / 12);
            flex-basis: percentage(2 / 12);
        }
        &--3of12sm {
            max-width: percentage(3 / 12);
            flex-basis: percentage(3 / 12);
        }
        &--4of12sm {
            max-width: percentage(4 / 12);
            flex-basis: percentage(4 / 12);
        }
        &--5of12sm {
            max-width: percentage(5 / 12);
            flex-basis: percentage(5 / 12);
        }
        &--6of12sm {
            max-width: percentage(6 / 12);
            flex-basis: percentage(6 / 12);
        }
        &--7of12sm {
            max-width: percentage(7 / 12);
            flex-basis: percentage(7 / 12);
        }
        &--8of12sm {
            max-width: percentage(8 / 12);
            flex-basis: percentage(8 / 12);
        }
        &--9of12sm {
            max-width: percentage(9 / 12);
            flex-basis: percentage(9 / 12);
        }
        &--10of12sm {
            max-width: percentage(10 / 12);
            flex-basis: percentage(10 / 12);
        }
        &--11of12sm {
            max-width: percentage(11 / 12);
            flex-basis: percentage(11 / 12);
        }
        &--12of12sm {
            max-width: percentage(12 / 12);
            flex-basis: percentage(12 / 12);
        }
    }
}

/**
 * ガターサイズ50px
 */

.c-grid--wide {
    margin-left: -$grid-gutter-wide;
    margin-bottom: -$margin-bottom-wide;
    @include _mq-down(md) {
        margin-bottom: -$margin-bottom-wide-md;
        margin-left: -$grid-gutter-wide-md;
    }
}

.c-grid--wide .c-grid__col {
    padding-left: $grid-gutter-wide;
    padding-bottom: $margin-bottom-wide;
    @include _mq-down(md) {
        padding-left: $grid-gutter-wide-md;
        padding-bottom: $margin-bottom-wide-md;
    }
}

/**
 * 要素の垂直位置を上寄せ。
 */

.c-grid--top {
    align-items: flex-start;
}
@include _mq-down(lg) {
    .c-grid--top-lg {
        align-items: flex-start;
    }
}
@include _mq-down(md) {
    .c-grid--top-md {
        align-items: flex-start;
    }
}
@include _mq-down(sm) {
    .c-grid--top-sm {
        align-items: flex-start;
    }
}

/**
 * 要素の垂直位置を真ん中寄せ。
 */

.c-grid--middle {
    align-items: center;
}
@include _mq-down(md) {
    .c-grid--middle-md {
        align-items: center;
    }
}
@include _mq-down(lg) {
    .c-grid--middle-lg {
        align-items: center;
    }
}
@include _mq-down(xl) {
    .c-grid--middle-xl {
        align-items: center;
    }
}

/**
 * 要素の垂直位置を下寄せ。
 */

.c-grid--bottom {
    align-items: flex-end;
}
@include _mq-down(md) {
    .c-grid--bottom-md {
        align-items: flex-end;
    }
}
@include _mq-down(lg) {
    .c-grid--bottom-lg {
        align-items: flex-end;
    }
}
@include _mq-down(xl) {
    .c-grid--bottom-xl {
        align-items: flex-end;
    }
}

/**
 * 要素を中央寄せ、要素内は左寄せ。
 */

.c-grid--center {
    justify-content: center;
}
@include _mq-down(md) {
    .c-grid--center-md {
        justify-content: center;
    }
}
@include _mq-down(lg) {
    .c-grid--center-lg {
        justify-content: center;
    }
}
@include _mq-down(xl) {
    .c-grid--center-xl {
        justify-content: center;
    }
}

/**
 * 要素を右寄せ、要素内は左寄せ。
 */

.c-grid--right {
    justify-content: flex-end;
}
@include _mq-down(md) {
    .c-grid--right-md {
        justify-content: flex-end;
    }
}
@include _mq-down(lg) {
    .c-grid--right-lg {
        justify-content: flex-end;
    }
}
@include _mq-down(xl) {
    .c-grid--right-xl {
        justify-content: flex-end;
    }
}

/**
 * 要素の反転。
 */

.c-grid--reverse {
    flex-direction: row-reverse;
}
@include _mq-down(md) {
    .c-grid--reverse-md {
        flex-direction: row-reverse;
    }
}
@include _mq-down(lg) {
    .c-grid--reverse-lg {
        flex-direction: row-reverse;
    }
}
@include _mq-down(xl) {
    .c-grid--reverse-xl {
        flex-direction: row-reverse;
    }
}
