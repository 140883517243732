// ----------------------------------------
//  Object - Utylity - margin
// ----------------------------------------

// margin- utility //
// auto margin //
.u-m-c {
    margin-right: auto !important;
    margin-left: auto !important;
}
.u-m-ch {
    margin: 0 auto;
}

// Top margin //
.u-mt0 {
    margin-top: 0 !important;
}
.u-mt5 {
    margin-top: 5px !important;
}
.u-mt10 {
    margin-top: 10px !important;
}
.u-mt15 {
    margin-top: 15px !important;
}
.u-mt20 {
    margin-top: 20px !important;
}
.u-mt25 {
    margin-top: 25px !important;
}
.u-mt30 {
    margin-top: 30px !important;
}
.u-mt45 {
    margin-top: 45px !important;
}
.u-mt60 {
    margin-top: 60px !important;
}

// Right margin //
.u-mr0 {
    margin-right: 0 !important;
}
.u-mr5 {
    margin-right: 5px !important;
}
.u-mr10 {
    margin-right: 10px !important;
}
.u-mr15 {
    margin-right: 15px !important;
}
.u-mr20 {
    margin-right: 20px !important;
}
.u-mr25 {
    margin-right: 25px !important;
}
.u-mr30 {
    margin-right: 30px !important;
}
.u-mr45 {
    margin-right: 45px !important;
}
.u-mr60 {
    margin-right: 60px !important;
}

// Bottom margin //
.u-mb0 {
    margin-bottom: 0 !important;
}
.u-mb5 {
    margin-bottom: 5px !important;
}
.u-mb10 {
    margin-bottom: 10px !important;
}
.u-mb15 {
    margin-bottom: 15px !important;
}
.u-mb20 {
    margin-bottom: 20px !important;
}
.u-mb25 {
    margin-bottom: 25px !important;
}
.u-mb30 {
    margin-bottom: 30px !important;
}
.u-mb45 {
    margin-bottom: 45px !important;
}
.u-mb60 {
    margin-bottom: 60px !important;
}

// Left margin //
.u-ml0 {
    margin-left: 0 !important;
}
.u-ml5 {
    margin-left: 5px !important;
}
.u-ml10 {
    margin-left: 10px !important;
}
.u-ml15 {
    margin-left: 15px !important;
}
.u-ml20 {
    margin-left: 20px !important;
}
.u-ml25 {
    margin-left: 25px !important;
}
.u-ml30 {
    margin-left: 30px !important;
}
.u-ml45 {
    margin-left: 45px !important;
}
.u-ml60 {
    margin-left: 60px !important;
}
