 /* #contact
   -------------------------------------------------------------------------- */
.contact_wrap {
    display: flex;
    margin-top: 55px;
    & .company {
        width: 50%;
        background: #fff;
        padding: 24px 20px 24px 30px;
        & .title {
            color: $color-brand;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 1.75;
        }
        & img {
            width: 277px;
            height: 26.5px;
            /*margin: 10px 0;*/
            margin: 5px 0;
        }
        & p {
            line-height: 1.5;
        }
    }
    & .tel {
        width: 30%;
        background: #f4fad3;
        & .title {
            color: #fff;
            font-size: 1.4rem;
            font-weight: 500;
            text-align: center;
            height: 40px;
            line-height: 40px;
            background: $color-brand;
        }
        .inner {
            /*height: 110px;
            padding: 39px 19px;*/
            height: 120px;
            padding: 45px 19px;
            text-align: center;
        }
        & img {
            width: 246px;
            height: 19.5px;
        }
        & p {
            font-size: 1.4rem;
            line-height: 1.5;
        }
    }
    & .mail {
        width: 20%;
        background: #f4fad3;
        border-left: 1px solid #fff;
        & .title {
            color: #fff;
            font-size: 1.4rem;
            font-weight: 500;
            text-align: center;
            height: 40px;
            line-height: 40px;
            background: $color-brand;
        }
        .inner {
            /*height: 110px;
            padding: 23px 5px;*/
            height: 120px;
            padding: 28px 5px;
            text-align: center;
        }
        & img {
            width: 55px;
            height: 41px;
        }
        & p {
            color: #464646;
            font-size: 1.0rem;
            line-height: 1.5;
            margin-top: 10px;
        }
    }
    @include _mq-down(md) {
        display: block;
        margin-top: 30px;
        & .company, .tel, .mail{
            width: 100%;
        }
        & .company {
            text-align: center;
            padding: 24px 10px 24px 10px;
            img {
                width: 250px;
                height: 24px;
            }
        }
    }
}

.txt-adjust {
    font-size: 1.6rem;
    margin-top: 1px;
    @include _mq-down(xl) {
        font-size: 1.3rem;
        margin-top: 3px;
    }
}
