// Object - project -

@include _mq-down(md) {
    .pc-only {
        display: none;
    }
}
@include _mq-up(md) {
    .sp-only {
        display: none;
    }
}

.bg-grass {
    background: url('../../assets/img/common/common_bg01.jpg');
    display: inline-block;
}

.pd-b_foot {
    padding-bottom: 110px;
    @include _mq-down(md) {
        padding-bottom: 50px;
    }
}

.p-intro {
    background-image: url('../../assets/img/common/image_intro-bg.jpg');
    height: 250px;
    position: relative;
    z-index: 10;
    &::before {
        content: "";
        position: absolute;
        background-image: url('../../assets/img/common/dot_bg-brand80.png');
        width: 100%;
        height: 250px;
    }
    &::after {
        content: "";
        position: absolute;
        background-image: url('../../assets/img/common/p-intro-illust.svg');
        width: 461px;
        height: 173px;
        bottom: 21px;
        right: 43px;
    }
}
@include _mq-down(md) {
    .p-intro {
        height: 150px;
        margin-bottom: 50px;
        background-position: center;
        background-size: cover;
        &::before {
            height: 150px;
        }
        &::after {
            background-image: url('../../assets/img/common/p-intro-illust_sp.svg');
            width: 323px;
            height: 115px;
            bottom: auto;
            right: auto;
            @include _centering();
            margin-left: 13px;
        }
        &.no-scroll {
            margin-bottom: 0;
        }
    }
}

.p-intro_title {
    font-size: 3.5rem;
    font-weight: 500;
    color: #fff;
    position: absolute;
    @include _centering_vertical();
    left: 48px;
    @include _mq-down(md) {
        font-size: 2.5rem;
        @include _centering();
    }
}

.p-intro_scroll_wrap {
    @include _mq-down(md) {
        background-image: url('../../assets/img/common/dot_bg-darkbrand.png');
        width: 100%;
        bottom: -50px;
        left: 0;
        height: 50px;
        padding: 9px;
        position: absolute;
    }
}

.p-intro_scroll {
    display: flex;
    position: absolute;
    left: 50px;
    bottom: 25px;
    li + li {
        margin-left: 30px;
    }
    @include _mq-down(md) {
        position: static;
        left: auto;
        justify-content: center;
        bottom: auto;
    }
}

/*arrow*/
.arrow {
    position: relative;
    display: inline-block;
    padding: 0 0 0 1.5em;
    color: #000;
    vertical-align: middle;
    text-decoration: none;
    font-size: 1.6rem;
}
.arrow::before,
.arrow::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
}

.circle-white::before {
    width: 1.1em;
    height: 1.1em;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #fff;
}
.circle-white::after {
    left: 6px;
    box-sizing: border-box;
    width: 4px;
    height: 6px;
    border: 4px solid transparent;
    border-left: 6px solid $color-brand;
}
.p-intro-arrow-style {
    font-size: 1.6rem;
    color: #fff;
    font-weight: 500;
}

.mt-head {
    margin-top: 62px;
    @include _mq-down(md) {
        margin-top: 30px;
    }
}
.mt-card-under {
    margin-top: 30px;
    padding-bottom: 20px;
}
.t-c {
    text-align: center;
}

.txt-table-sub {
    font-size: 1.6rem;
    font-weight: 500;
    margin-top: 5px;
}
.txt-secondly {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.75;
    @include _mq-down(md) {
        font-size: 1.4rem;
    }
}
.c-secondly {
    font-size: 1.75rem;
    font-weight: 500;
}
.c-thirdry {
    font-size: 1.6rem;
    font-weight: 500;
}

@include _mq-down(md) { /*spのみ調整*/
    .anchor {
        display: block;
        margin-top: -90px;
        padding-top: 90px;
    }
}

#brand-table .brand-table-inner {
    font-size: 16px;
    margin-top: 25px;
    @include _mq-down(md) {
        margin-bottom: 50px;
    }
}

.company_list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    & li {
        padding-left: 1.1em;
        padding-right: 1em;
    }
    & li::before {
        content: "■";
        position: absolute;
        color: $color-brand;
        width: 1em;
        height: 1em;
        margin-left: -1.1em;
    }
    /*& li:last-child::after {
        content: "";
    }*/
    @include _mq-down(md) {
        justify-content: center;
        /*text-align: left;*/
    }
}
.table-mt {
    margin-top: 90px;
    padding-top: 10px;
    @include _mq-down(md) {
        margin-top: 40px;
        padding: 0;
    }
}

#contain01 { /*スクロールリンクの調整*/
    position: relative;
    z-index: 1;
}

/*--sitemap--*/
.content-sitemap_link span{
    color: #ccc;
    font-size: 1.75rem;
    margin-right: 5px;
}
.content-sitemap_link a{
    color: #6da920;
    font-size: 1.75rem;
    margin-bottom: 20px;
    display: inline-block;
}


.txtLink {
    a {
        color: #6eaa1e;
        border-bottom: 1px solid #6eaa1e;
    }
}



