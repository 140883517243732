 /* #FormCheckbox
   -------------------------------------------------------------------------- */
 /*doc
---
name: FormCheckbox
category: Component
tag: Form
---

`type="checkbox"`のデフォルトスタイルです。

```ejs
<input class="c-form-checkbox" type="checkbox" id="checkbox1">
<label for="checkbox1">チェックボックス</label>

<input disabled class="c-form-checkbox" type="checkbox" id="checkbox2">
<label disabled for="checkbox2">チェックボックス</label>
```
*/

 .c-form-checkbox {
     display: none;
     &+label,
     &+span {
         position: relative;
         display: inline-block;
         cursor: pointer;
         padding-left: 1.2em;
     }
     &+label:before,
     &+span:before {
         content: "";
         position: absolute;
         display: inline-block;
         top: 50%;
         transform: translateY(-50%);
         left: 0;
         width: 1em;
         height: 1em;
         margin: auto;
         margin-right: 0.25em;
         border: 1px solid #d0d0d0;
         border-radius: $form-border-radius;
         background-color: #fff;
     }
     &:checked+label:after,
     &:checked+span:after {
         content: "";
         background-image: url("../img/common/icon/FormCheckbox.svg");
         background-repeat: no-repeat;
         background-size: 1em;
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         left: 0;
         margin: auto;
         width: 1em;
         height: 1em;
     }
     &:disabled,
     &+label[disabled],
     &+span[disabled] {
         cursor: $form-disabled-cursor;
         opacity: $form-disabled-opacity;
     }
     &:disabled+label:after,
     &:disabled+span:after {
         display: none;
     }
 }
