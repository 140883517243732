// ----------------------------------------
//  Object - Utylity - display
// ----------------------------------------

// display - utility //
.u-d-none {
    display: none;
}
.u-d-table {
    display: table;
}
.u-d-table-cell {
    display: table-cell;
}
.u-d-inline-block {
    display: inline-block;
}
.u-d-block {
    display: block;
}
.u-d-flex {
    -js-display: flex;
    display: flex;
}

.u-d-inline-flex {
    display: inline-flex;
}

.u-d-none {
    &-sm {
        @include _mq-down(sm) {
            display: none !important;
        }
    }
    &-md {
        @include _mq-down(md) {
            display: none !important;
        }
    }
    &-lg {
        @include _mq-down(lg) {
            display: none !important;
        }
    }
    &-xl {
        @include _mq-down(xl) {
            display: none !important;
        }
    }
}

.u-d-inline {
    &-sm {
        @include _mq-down(sm) {
            display: inline !important;
        }
    }
    &-md {
        @include _mq-down(md) {
            display: inline !important;
        }
    }
    &-lg {
        @include _mq-down(lg) {
            display: inline !important;
        }
    }
    &-xl {
        @include _mq-down(xl) {
            display: inline !important;
        }
    }
}

.u-d-inline-block {
    &-sm {
        @include _mq-down(sm) {
            display: inline-block !important;
        }
    }
    &-md {
        @include _mq-down(md) {
            display: inline-block !important;
        }
    }
    &-lg {
        @include _mq-down(lg) {
            display: inline-block !important;
        }
    }
    &-xl {
        @include _mq-down(xl) {
            display: inline-block !important;
        }
    }
}

.u-d-block {
    &-sm {
        @include _mq-down(sm) {
            display: block !important;
        }
    }
    &-md {
        @include _mq-down(md) {
            display: block !important;
        }
    }
    &-lg {
        @include _mq-down(lg) {
            display: block !important;
        }
    }
    &-xl {
        @include _mq-down(xl) {
            display: block !important;
        }
    }
}
.u-d-flex {
    &-sm {
        @include _mq-down(sm) {
            display: flex !important;
        }
    }
    &-md {
        @include _mq-down(md) {
            display: flex !important;
        }
    }
    &-lg {
        @include _mq-down(lg) {
            display: flex !important;
        }
    }
    &-xl {
        @include _mq-down(xl) {
            display: flex !important;
        }
    }
}
.u-d-flex-inline {
    &-sm {
        @include _mq-down(sm) {
            display: inline-flex !important;
        }
    }
    &-md {
        @include _mq-down(md) {
            display: inline-flex !important;
        }
    }
    &-lg {
        @include _mq-down(lg) {
            display: inline-flex !important;
        }
    }
    &-xl {
        @include _mq-down(xl) {
            display: inline-flex !important;
        }
    }
}
// responsive display - utility //
.u-display {
    &-sm {
        @include _mq-up(sm) {
            display: none !important;
        }
    }
    &-md {
        @include _mq-up(md) {
            display: none !important;
        }
    }
    &-lg {
        @include _mq-up(lg) {
            display: none !important;
        }
    }
    &-xl {
        @include _mq-up(xl) {
            display: none !important;
        }
    }
}

.u-hide {
    &-sm {
        @include _mq-down(sm) {
            display: none !important;
        }
    }
    &-md {
        @include _mq-down(md) {
            display: none !important;
        }
    }
    &-lg {
        @include _mq-down(lg) {
            display: none !important;
        }
    }
    &-xl {
        @include _mq-down(xl) {
            display: none !important;
        }
    }
}

// Utilities for toggling `display` in print //

@media print {
    .u-d-print-none {
        display: none !important;
    }
    .u-d-print-inline {
        display: inline !important;
    }
    .u-d-print-inline-block {
        display: inline-block !important;
    }
    .u-d-print-block {
        display: block !important;
    }
    .u-d-print-table {
        display: table !important;
    }
    .u-d-print-table-row {
        display: table-row !important;
    }
    .u-d-print-table-cell {
        display: table-cell !important;
    }
    .u-d-print-flex {
        display: flex !important;
    }
    .u-d-print-inline-flex {
        display: inline-flex !important;
    }
}

// float - utility //
.u-fl-left {
    float: left !important;
}
.u-fl-right {
    float: right !important;
}
.u-fl-none {
    float: none;
}
