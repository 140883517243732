/* #ListNote
   -------------------------------------------------------------------------- */
/*doc
---
name: List
category: Component
tag: List
---

リストのデフォルトスタイルです。

```ejs
<ul class="c-list">
  <li>1つ目のリストです</li>
  <li>2つ目のリストです</li>
</ul>
<ul class="c-list c-list--inside">
<li>リストをコンテンツの内側に収めたいときは</li>
<li>modifire".c-list--inside"をつける</li>
</ul>
```
*/

.c-list {
    padding-left: 0;
    list-style-type: disc;
    &--inside {
        margin-left: 1.5em;
    }
}
