 /* #LinkPdf
   -------------------------------------------------------------------------- */
/*doc
---
name: LinkPdf
category: Component
tag: Link
---

リンク先がPDFであることを示す場合に使います。

```ejs
<p><a class="c-link" href="#">テキストリンクです。</a></p>
<p><a class="c-link-pdf" href="#">PDFをダウンロードする<span class="c-link-pdf__icon" aria-hidden="true"></span></a></p>
```
*/
.c-link-pdf {
  color: $color-link;
  text-decoration: none;

  &:hover {
    color: $color-link-hover;
    text-decoration: underline;
  }
}

.c-link-pdf__icon:after {
  @include icon;
  content: "#{$icon-LinkPdf}";
  top: -0.2em;
  left: 0.25em;
  font-size: 1.25em;
}
