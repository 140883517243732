 /* #FormInput
   -------------------------------------------------------------------------- */
 /*doc
---
name: FormInput
category: Component
tag: Form
---

`<input>`タグのデフォルトスタイルです。

```ejs
<div>
  <input class="c-form-input" type="input" placeholder="デフォルト" />
</div>

<div>
  <label class="c-form-input_TextTop" for="input1">inputタグの説明です
  <input class="c-form-input" type="input" name="email" autocomplete="email" id="input1" placeholder="example@gmail.com" />
</div>

<div>
  <input class="c-form-input" type="input" name="name" autocomplate="name" id="input2" placeholder="山田 太郎" />
  <label class="c-form-input_txt-bottom" for="input2">inputタグの説明です
</div>

<div>
  <input disabled class="c-form-input" type="input" placeholder="disabled"/ >
</div>
```
*/
.c-form-input {
    height: 2.8em;
    padding: 0 1em;
    vertical-align: middle;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: $form-border-radius;
    appearance: none;
    transition: ease-in-out 0.2s;
    &::placeholder {
        color: $form-placeholder-color;
    }
    &:focus {
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 10px rgba(204, 204, 204, 0.6);
        @include _font-rem(16);
    }
    &:disabled {
        cursor: $form-disabled-cursor;
        opacity: $form-disabled-opacity;
        background-color: $form-disabled-background-color;
    }
}
.c-form-input__txt-top {
    display: inline-block;
    margin-bottom: 0.25em;
}
.c-form-input__txt-bottom {
    display: inline-block;
    margin-top: 0.25em;
}
