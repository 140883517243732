 /* #TextEmphasis
   -------------------------------------------------------------------------- */
/*doc
---
name: TextEmphasis
category: Component
tag: Text
---

`<p>`や`<strong>`で使われるようなスタイルです。強調や重要性を示すときに使います。

```ejs
<p>通常の文章です、<strong class="c-text-emphasis">強調したい文章</strong>です。</p>
```
*/
.c-text-emphasis {
  font-weight: 700;
}
