// ----------------------------------------
// 	Object - Utylity - position
// ----------------------------------------

// position - utility //
.u-pos-a { position: absolute !important; }
.u-pos-r { position: relative !important; }

// z-index - utility //
.u-z-a { z-index: auto !important; }
.u-z-0 { z-index: 0 !important; }
.u-z-1 { z-index: 1 !important; }
.u-z-2 { z-index: 2 !important; }
.u-z-3 { z-index: 3 !important; }
.u-z-4 { z-index: 4 !important; }
.u-z-5 { z-index: 5 !important; }
.u-z-6 { z-index: 6 !important; }
.u-z-9999 { z-index: 9999 !important; }