// ----------------------------------------
//  Object - Utylity - others
// ----------------------------------------
// border - utility //
// border-style //
.u-bs-n {
    border-style: none !important;
}
.u-bs-h {
    border-style: hidden !important;
}
.u-bs-dt {
    border-style: dotted !important;
}
.u-bs-ds {
    border-style: dashed !important;
}
.u-bs-s {
    border-style: solid !important;
}
.u-bs-db {
    border-style: double !important;
}
.u-bs-g {
    border-style: groove !important;
}
.u-bs-r {
    border-style: ridge !important;
}
.u-bs-i {
    border-style: inset !important;
}
.u-bs-o {
    border-style: outset !important;
}
// border-radius //
.u-br2 {
    border-radius: 2px;
}
.u-br4 {
    border-radius: 4px;
}
.u-br8 {
    border-radius: 8px;
}
.u-br12 {
    border-radius: 12px;
}
.u-br16 {
    border-radius: 16px;
}
// border-none //
.u-br0 {
    border: none !important;
}
.u-br-t0 {
    border-top: none !important;
}
.u-br-r0 {
    border-right: none !important;
}
.u-br-b0 {
    border-bottom: none !important;
}
.u-br-l0 {
    border-left: none !important;
}
// misc - utility //
.u-ws-nowrap {
    white-space: nowrap !important;
}
.u-mx-img {
    max-width: 100% !important;
}
.u-tx-inside {
    margin-left: 1em !important;
    text-indent: -1em !important;
}
