 /* #Footer
   -------------------------------------------------------------------------- */
.l-footer {}
.l-footer__copryright-area {}
.l-footer__copryright {}

/*footer ---------------------------------------*/
footer {
    width: 100%;
    background-color: #6EAA1E;
    display: -webkit-flex;
    display: flex;
    position: relative;
    font-weight: 300;
}
.ft_l-wrap{
    width: 25%;
    display: block;
    position: relative;
    background-color: #87B25F;
}
.ft_logo{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: 0;
    transform: translate(-50%, -50%)
}
.ft_r-wrap{
    width: 75%;
    display: block;
    position: relative;
}
.ft_address{
    width: 100%;
    /*padding: 85px 20px 115px 0;*/
    padding: 75px 20px 105px 0;
    box-sizing: border-box;
    color: #fff;
    text-align: right;
}
.ft_address figure img{
    width: 370px;
    height: 40px;
    margin-bottom: 15px;
}
.ft_address p{
    line-height: 1.5;
}
.ft_address p span{
    font-size: 1.9rem;
}

.ft_link{
    width: 100%;
    height: 50px;
    padding: 11px 0;
    position: absolute;
    bottom: 0px;
    display: inline-block;
    background-color: rgba(0,0,0,0.2);
}
.ft_link ul{
    display: inline-block;
    float: right;
}
.ft_link ul li{
    float: left;
    margin: 0px 20px 0px 0px;
}
.ft_link ul li a{
    font-size: 1.4rem;
    color: #fff;
    text-decoration: none;
    padding-left: 20px;
    background: url('../../assets/img/common/ft_arrow.svg');
    background-repeat: no-repeat;
    background-position: 3%;
}
.ft_sp-copyright{
    display: none;
}
@include _mq-down(md) {
    footer{
        padding-top: 10px;
        display: inline-block;
    }
    .ft_l-wrap{
        width: 100%;
        height: 220px;
        background-color: #6EAA1E;
    }
    .ft_r-wrap{
        width: 100%;
    }
    .ft_address {
        width: 90%;
        padding: 0px 0px 30px;
        box-sizing: border-box;
        margin: auto;
        text-align: center;
    }
    .ft_address figure img{
        width: 270px;
        margin-bottom: 0px;
    }
    .ft_address p + p {
        margin-top: 3px;
    }
    .ft_link{
        position: relative;
        margin-bottom: -12px;
        height: auto;
        padding: 0;
    }
    .ft_link ul{
        width: 100%;
    }
    .ft_link ul li {
        width: 50%;
        margin: 0px;
        padding: 0;
        border-right: 1px solid #6EAA1E;
        border-bottom: 1px solid #6EAA1E;
        box-sizing: border-box;
    }
    .ft_link ul li a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 18px 0 18px 40px;
        line-height: 1;
        background-position: 20px center;
    }
    .ft_sp-copyright{
        display: block;
        color: #fff;
        font-size: 0.8rem;
        line-height: 1.5;
        text-align: center;
        padding: 15px 0px;
        box-sizing: border-box;
        background-color: #87B25F;
    }
}

.l-totop_wrap {
    position: relative;
}

.l-totop {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 50px;
    top: -25px;
    z-index: 1;

}
