 /* #TextAttention
   -------------------------------------------------------------------------- */
/*doc
---
name: TextAttention
category: Component
tag: Text
---

`<p>`や`<strong>`で使われるようなスタイルです。注意や注目を引きたい場合に使います。

```ejs
<p>通常の文章です、<strong class="c-txt-attention">注意や注目を引きたい文章</strong>です。</p>
```
*/
.c-text-attention {
  color: $color-brand;
}
