/*doc
---
name: empty
category: Utility
tag: [Helper]
---

コンテンツを非表示にするヘルパーClassです。

```ejs
<p><span class="u-sr-only">説明：</span>このヘルパーClassが当たっている要素はブラウザ上には表示されませんが<br>読み上げソフトなどには認識されます<br>
アイコンなどの空要素に文脈を与えるために使います。</p>
```
*/

.u-sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
    clip: rect(0 0 0 0) !important;
    word-wrap: normal !important;
    margin: -1px !important;
    padding: 0 !important;
    border: 0 !important;
}
