// ==========================================================
//  Layout - main
// ==========================================================
.l-wrap {
    @include _wrapper($wrapper-width);
}
.l-wrap-wide {
    @include _wrapper($wrapper-width-wide);
}
.l-wrap-max {
    @include _wrapper($wrapper-width-max);
}
.l-wrap-fluid {
    @include _wrapper-fluid($wrapper-width);
}

.l-wrap-content{
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
}

.l-wrap-content_md {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
    @include _mq-down(md) {
        width: 90%;
    }
}
