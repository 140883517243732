 /* #Divider
   -------------------------------------------------------------------------- */
 /*doc
---
name: Divider
category: Component
tag: Text
---

`<hr>`のような区切り記号を使って、分割・グルーピングします。

```ejs
<p>テキストです。</p>
<hr class="c-divider">
<p>テキストです。</p>
```
*/

.c-divider {
    box-sizing: inherit;
    display: block;
    width: 100%;
    height: auto;
    margin: 1.2em auto;
    border: 0;
    border-top: 1px solid #e5e5e5;
}
@include _mq-down(md) {
    .c-divider {}
}

