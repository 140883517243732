 /* #FormTextarea
   -------------------------------------------------------------------------- */
/*doc
---
name: FormTextarea
category: Component
tag: Form
---

`<textarea>`のデフォルトスタイルです。

```ejs
<textarea class="c-form-textarea" placeholder="テキストエリアです"></textarea>

<label class="c-form-textarea__txt-top" for="textarea1">テキストエリアの説明</label>
<textarea class="c-form-textarea" id="textarea1" placeholder="テキストエリアです"></textarea>

<textarea class="c-form-textarea" id="textarea2" placeholder="テキストエリアです"></textarea>
<label class="c-form-textarea__txt-top" for="textarea2">テキストエリアの説明</label>

<textarea class="c-form-textarea c-form-textarea--large" id="textarea3" placeholder="テキストエリアです"></textarea>

<textarea disabled class="c-form-textarea" id="textarea4" placeholder="テキストエリアです"></textarea>
```
*/
.c-form-textarea {
  display: block;
  max-width: 100%;
  width: 100%;
  height: 7em;
  margin: 0;
  padding: 0.5em 1em;
  font-size: 1em;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow:1px 1px 6px -3px #9e9e9e inset;
  border-radius: $form-border-radius;
  appearance: none;
  transition: ease-in-out 0.2s;
  &::placeholder {
    color: $form-placeholder-color;
  }

  &:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 10px rgba(204, 204, 204, 0.6);
  }

  &:disabled {
    cursor: $form-disabled-cursor;
    opacity: $form-disabled-opacity;
    background-color: $form-disabled-background-color;
  }
}

.c-form-textarea--large {
  height: 14em;
}

.c-form-textarea__txt-top {
  display: inline-block;
  margin-bottom: 0.25rem;
}

.c-form-textarea__txt-bottom {
  display: inline-block;
  margin-top: 0.25rem;
}
